
import { getSystemFilesURL } from "@/helpers";
import Vue from "vue";
export default Vue.extend({
  computed: {
    bgPattern() {
      return getSystemFilesURL("bg-pattern1");
    }
  }
});
